
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('farm_config.supplier_wise_machine_assign') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      :label="$t('farm_config.company_name')"
                      label-for="supplier_id"
                      >
                      <b-form-select
                          plain
                          v-model="search.supplier_id"
                          :options="supplierLists"
                          id="supplier_id"
                          >
                          <template v-slot:first>
                          <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      :label="$t('common_config.farmer_mobile_name')"
                      label-for="mobile_no"
                      >
                      <b-form-input
                          plain
                          v-model="search.mobile_no"
                          id="mobile_no"
                          >
                      </b-form-input>
                  </b-form-group>
              </b-col> -->
                <b-col xs="12" sm="12" md="3" lg="3" xl="3" style="margin-top:36px">
                  <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                </b-col>
              </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('farm_config.supplier_wise_machine_assign') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button variant="primary btn_add_new" v-b-modal.modal-4 @click="resetId">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </b-button>
                    </template>
                    <template v-slot:body>
                      <b-overlay :show="loadingState">
                        <b-row>
                          <b-col md="12" class="table-responsive">
                            <b-table thead-class="table_head" striped :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                <template v-slot:cell(index)="data">
                                  {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(division)="data">
                                  {{ getDivisionList(data.item.division_id) }}
                                </template>
                                <template v-slot:cell(district)="data">
                                  {{ getDistrictList(data.item.district_id) }}
                                </template>
                                <template v-slot:cell(supplier)="data">
                                  {{ getSupplierList(data.item.supplier_id) }}
                                </template>
                                <template v-slot:cell(count)="data">
                                  {{ $n(data.item.details_count, { useGrouping: false }) }}
                                </template>
                                <template v-slot:cell(mobile)="data">
                                  {{ getSupplierMobileNumberList(data.item.supplier_id) }}
                                </template>
                                <template v-slot:cell(status)="data">
                                  <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                  <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                </template>
                                <template v-slot:cell(action)="data">
                                  <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 size="sm" @click="details(data.item)">
                                      <i class="fas fa-eye"></i>
                                  </a>
                                  <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                                      <i class="ri-ball-pen-fill"></i>
                                  </a>
                                  <a href="javascript:" class="btn_table_action table_action_view" title="Status" v-if="data.item.status === 1" @click="remove(data.item)">
                                      <i class="fas fa-toggle-on"></i>
                                  </a>
                                  <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)">
                                      <i class="fa fa-toggle-off"></i>
                                  </a>
                                </template>
                            </b-table>
                            <b-pagination
                              align="center"
                              v-model="pagination.currentPage"
                              :per-page="pagination.perPage"
                              :total-rows="pagination.totalRows"
                              @input="searchData"
                            />
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
         <b-modal id="modal-5" size="xl" :title="detailsTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :item="item" :key="dtlsItemId"></Details>
        </b-modal>
        <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Form from './Form'
import Details from './Details'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { SupplierMachineList, circularInfoStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form,
    Details
  },
  data () {
    return {
      search: {
        mobile_no: '',
        supplier_id: 0
      },
      item: '',
      dtlsItemId: '',
      supplierList: [],
      supplierLists: [],
      districtList: []
    }
  },
  created () {
    this.loadData()
    this.getSupplierName()
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('farm_config.supplier_wise_machine_assign') + ' ' + this.$t('globalTrans.entry') : this.$t('farm_config.supplier_wise_machine_assign') + ' ' + this.$t('globalTrans.modify')
    },
    detailsTitle () {
      return this.$t('farm_config.supplier_wise_machine_assign') + ' ' + this.$t('globalTrans.details')
    },
    columns () {
      const labels = [
        { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
        { label: this.$t('farm_config.company_name'), class: 'text-center' },
        { label: this.$t('globalTrans.mobile'), class: 'text-center' },
        { label: this.$t('farm_config.machine_allocation'), class: 'text-center' },
        { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
      ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'supplier' },
          { key: 'mobile' },
          { key: 'count' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'supplier' },
          { key: 'mobile' },
          { key: 'count' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictDropdownList(newVal)
      }
    }
  },
  methods: {
    getDivisionList (id) {
      const obj = this.$store.state.commonObj.divisionList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getDistrictList (id) {
      const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getSupplierList (id) {
      const obj = this.supplierList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getSupplierMobileNumberList (id) {
      const obj = this.supplierList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? '0' + '' + this.$n(obj.mobile, { useGrouping: false }) : '০' + '' + this.$n(obj.mobile, { useGrouping: false })
      }
    },
    getDistrictDropdownList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
          return districtList.filter(district => district.division_id === divisionId)
        }
      return districtList
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    details (item) {
      this.item = item
      this.dtlsItemId = item.id
    },
    remove (item) {
      this.changeStatus(incentiveGrantServiceBaseUrl, circularInfoStatus, item, 'incentive_grant', 'subsidyCircular')
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, SupplierMachineList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', response.data.data)
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    async getSupplierName () {
      this.loader = true
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, '/farmMachinery/config/supplier-wise-machine-assign/supplier-list')
      if (result.success) {
        this.supplierList = result.data.map(item => {
          const tmp = (this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }, { mobile: item.mobile_no })
          return Object.assign({}, item, tmp)
        })
        this.supplierLists = result.data.map(item => {
          const tmp = (this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn })
          return Object.assign({}, item, tmp)
        })
      } else {
        this.supplierList = []
        this.supplierLists = []
      }
      this.loader = false
    }
  }
}
</script>
