<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <b-overlay :show="loading">
                        <div class="mx-2">
                           <b-table-simple small resonsive borderless>
                              <b-tr style="font-size:23px">
                                <b-th width="18%">{{ $t('farm_config.company_name') }}</b-th>
                                <b-th width="2%">:</b-th>
                                <b-td>
                                  {{ getSupplierList(detailsData.supplier_id) }}
                                </b-td>
                              </b-tr>
                            </b-table-simple>
                        </div>
                        <!-- farmer list -->
                         <div  class="mx-2">
                            <b-table-simple striped bordered small class="mt-2">
                              <b-thead>
                                  <tr class="text-center">
                                        <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.origin_type') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.machine_type') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.machine_name') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.brand') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.country_origin_name') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.country_prepared_name') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.horse_power') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.model_no') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.extended_model_no') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.plain_area') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.coastal_area') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.comment') }}</b-th>
                                  </tr>
                              </b-thead>
                              <b-tbody>
                                  <template v-if="Object.keys(detailsData.details).length > 0">
                                      <b-tr v-for="(item, index) in detailsData.details" :key="'farmar-list-' + index">
                                           <b-td  class="text-center">{{ $n(index + 1) }}</b-td>
                                            <b-td class="text-center">{{ getOriginTypeList(item.origin_type) }}</b-td>
                                            <b-td class="text-center">{{ getMachineTypeName(item.machine_type_id) }}</b-td>
                                            <b-td class="text-center">{{ getMachineList(item.machine_id) }}</b-td>
                                            <b-td class="text-center">{{ getBrandList(item.brand_id) }}</b-td>
                                            <b-td class="text-center">{{ getCountryList(item.country_origin_id) }}</b-td>
                                            <b-td class="text-center">{{ getCountryList(item.country_prepared_id) }}</b-td>
                                            <b-td class="text-center">{{ $n(item.horse_power, { useGrouping: false }) }}</b-td>
                                            <b-td class="text-center">{{ item.model }}</b-td>
                                            <b-td class="text-center">{{ item.extended_model }}</b-td>
                                            <b-td class="text-center">{{ $n(item.plain_area, { useGrouping: false }) }}</b-td>
                                            <b-td class="text-center">{{ $n(item.coastal_area, { useGrouping: false }) }}</b-td>
                                            <b-td class="text-center">{{ item.comment }}</b-td>
                                      </b-tr>
                                  </template>
                                  <template v-else>
                                      <b-tr>
                                          <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                      </b-tr>
                                  </template>
                              </b-tbody>
                          </b-table-simple>
                        </div>
                        </b-overlay>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['item'],
  data () {
    return {
      detailsData: {},
      slOffset: 1,
      loadMechineType: false,
      loading: false,
      supplierList: []
    }
  },
  created () {
    this.detailsData = this.item
    this.getSupplierName()
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    currentLocale () {
        return this.$i18n.locale
    },
    id () {
      return this.$route.params.id
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      this.getMachineTypeList()
    }
  },
  methods: {
    async getSupplierName () {
      this.loading = true
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, '/farmMachinery/config/supplier-wise-machine-assign/supplier-list')
      if (result.success) {
        this.supplierList = result.data.map(item => {
          const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
        })
      } else {
        this.supplierList = []
      }
      this.loading = false
    },
    getDivisionList (id) {
      const obj = this.$store.state.commonObj.divisionList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getDistrictList (id) {
      const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getSupplierList (id) {
      const obj = this.supplierList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getMachineList (id) {
      const obj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getBrandList (id) {
      const obj = this.$store.state.incentiveGrant.commonObj.macBrandList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getMachineTypeName (id) {
      const obj = this.$store.state.incentiveGrant.commonObj.macMachineTypeList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getOriginTypeList (status) {
      if (status === 1) {
        return this.$i18n.locale === 'bn' ? 'স্থানীয়' : 'Local'
      } else if (status === 2) {
        return this.$i18n.locale === 'bn' ? 'বিদেশী' : 'Foreign'
      }
    },
    getCountryList (id) {
      const obj = this.$store.state.commonObj.countryList.find(el => el.value === parseInt(id))
      if (obj !== undefined) {
        return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
      }
    },
    getBrandName (id) {
      const obj = this.$store.state.incentiveGrant.commonObj.macBrandList.find(item => item.value === id)
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
