<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                      <div style="padding:20px">
                        <b-row>
                          <b-col lg="4" md="4" sm="12" xs="12">
                            <ValidationProvider name="company" vid="supplier_id" rules="required">
                              <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="supplier_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('farm_config.company_name') }} <span class="text-danger">*</span>
                                </template>
                                <v-select name="supplier_id"
                                  v-model="formData.supplier_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options=supplierList
                                  :placeholder="loader ? 'Loading..' : $t('globalTrans.select')"
                                />
                                <div class="d-block invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                    </div>
                      <b-overlay v-if="instrumentShow">
                        <template>
                          <div style="font-size:14px; background-color: #66CC99; padding:6px" class="mt-4">
                              <h5 class="text-white text-center"> {{ $t('dae_config.instrument_info') }}</h5>
                          </div>
                        </template>
                        <div style="border: 1px solid black; padding: 8px" class="mt-3">
                          <b-row v-for="(info, index2) in formData.details" :key="index2" class="mt-3">
                            <b-col lg="2" md="2" sm="12" xs="12">
                              <ValidationProvider name="Origin Type" vid="`origin_type$(index)`" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="`origin_type$(index)`"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('farm_config.origin_type') }}<span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                  plain
                                  v-model="info.origin_type"
                                  :options="typeList"
                                  id="origin_type"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" xs="12">
                              <ValidationProvider name="Machine type" vid="`machine_type_id$(index)`" rules="required">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="`machine_type_id$(index)`"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('farm_config.machine_type') }}<span class="text-danger">*</span>
                                  </template>
                                  <v-select name="machine_type_id"
                                  v-model="info.machine_type_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options=machineTypeList
                                  :placeholder="$t('globalTrans.select')"
                                  @input="getMachineNameList(info)"
                                  />
                                  <div class="d-block invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col lg="2" md="2" sm="12" xs="12">
                              <ValidationProvider name="Machine Name" vid="`machine_id$(index)`" rules="required">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="`machine_id$(index)`"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('farm_config.machine_name') }}<span class="text-danger">*</span>
                                  </template>
                                  <v-select name="machine_id"
                                  v-model="info.machine_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options=info.machineList
                                  :placeholder="$t('globalTrans.select')"
                                  @input="getBrandList(info)"
                                  />
                                  <div class="d-block invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <b-col lg="2" md="2" sm="12" xs="12">
                              <ValidationProvider name="Machine Name" vid="`machine_id$(index)`" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="`machine_id$(index)`"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('farm_config.machine_name') }}<span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                  plain
                                  v-model="info.machine_id"
                                  :options="info.machineList"
                                  id="machine_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  @input="getBrandList(info)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" xs="12">
                              <ValidationProvider name="Brand Name" vid="`brand_id$(index)`" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="`brand_id$(index)`"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('farm_config.brand') }}<span class="text-danger">*</span>
                                  </template>
                                <b-form-select
                                  plain
                                  v-model="info.brand_id"
                                  :options="info.brandNameList"
                                  id="brand_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" xs="12">
                              <ValidationProvider name="Country Origin Name" vid="`country_origin_id$(index)`" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="`country_origin_id$(index)`"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('farm_config.country_origin_name') }}<span class="text-danger">*</span>
                                  </template>
                                  <v-select name="country_origin_id"
                                  v-model="info.country_origin_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options=countryList
                                  :placeholder="$t('globalTrans.select')"
                                  />
                                  <div class="d-block invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" xs="12">
                              <ValidationProvider name="Country Prepared Name" vid="`country_prepared_id$(index)`" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12"
                                  label-for="`country_prepared_id$(index)`"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('farm_config.country_prepared_name') }}<span class="text-danger">*</span>
                                  </template>
                                  <v-select name="country_prepared_id"
                                  v-model="info.country_prepared_id"
                                  label="text"
                                  :reduce="item => item.value"
                                  :options=preparedCountryList
                                  :placeholder="$t('globalTrans.select')"
                                  />
                                  <div class="d-block invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12" xs="12">
                              <ValidationProvider name="Horse Power" vid="`horse_power$(index)`" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="`horse_power$(index)`"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('farm_config.horse_power')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-input
                                    id="horse_power"
                                    v-model="info.horse_power"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12" xs="12">
                              <ValidationProvider name="Model No" vid="`model$(index)`" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="`model$(index)`"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('farm_config.model_no')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-input
                                    id="model"
                                    v-model="info.model"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12" xs="12">
                              <ValidationProvider name="Extended Model No" vid="`extended_model$(index)`">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="`extended_model$(index)`"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('farm_config.extended_model_no')}}
                                </template>
                                    <b-form-input
                                    id="extended_model"
                                    v-model="info.extended_model"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12" xs="12">
                              <ValidationProvider name="Plain Area" vid="`plain_area$(index)`" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="`plain_area$(index)`"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('farm_config.plain_area')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-input
                                    id="plain_area"
                                    v-model="info.plain_area"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="3" md="3" sm="12" xs="12">
                              <ValidationProvider name="Coastal Area" vid="`coastal_area$(index)`" rules="required">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="`coastal_area$(index)`"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('farm_config.coastal_area')}} <span class="text-danger">*</span>
                                </template>
                                    <b-form-input
                                    id="coastal_area"
                                    v-model="info.coastal_area"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" xs="12">
                              <ValidationProvider name="Comment" vid="comment">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="comment"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('farm_config.comment')}}
                                </template>
                                    <b-form-input
                                    id="comment"
                                    v-model="info.comment"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col xl="3" lg="3" md="3" sm="12" style="margin-top:3%">
                              <b-button v-show="index2 == formData.details.length-1" variant=" iq-bg-success" size="sm" @click="add()"><i class="ri-add-line m-0"></i>{{$t('dae_config.add_more')}}</b-button>
                              <b-button v-show="index2 || ( !index2 && formData.details.length > 1)" variant=" iq-bg-danger" size="sm" @click="remove(index2)"><i class="ri-delete-bin-line m-0"></i></b-button>
                            </b-col>
                          </b-row>
                        </div>
                      </b-overlay>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right mt-3">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { SupplierMachineStore, SupplierMachineUpdate } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      instrumentShow: true,
      totFarmerSHow: false,
      isCashAmountShow: false,
      totalPost: 0,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
      temp_is_cash_amount: false,
      loader: false,
      temp_circular_type: 0,
      formData: {
        supplier_id: '',
        details: [
          {
            origin_type: 0,
            machine_type_id: '',
            machine_id: 0,
            brand_id: 0,
            country_origin_id: '',
            country_prepared_id: '',
            horse_power: '',
            model: '',
            extended_model: '',
            plain_area: '',
            coastal_area: '',
            brandNameList: [],
            machineList: [],
            comment: ''
          }
        ]
      },
      districtList: [],
      supplierList: [],
      brandNameList: [],
      machineList: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getSupplierMachineData()
      tmp.details = tmp.details.map(item => {
        return Object.assign({}, this.getBrandList(item), this.getMachineNameList(item))
      })
      this.formData = tmp
    }
    this.getSupplierName()
  },
  computed: {
    typeList: function () {
      const list = [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'স্থানীয়' : 'Local' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'বিদেশী' : 'Foreign' }
      ]
      return list
    },
    brandList: function () {
      return this.$store.state.incentiveGrant.commonObj.macBrandList
    },
    preparedCountryList: function () {
      const list = this.$store.state.commonObj.countryList.filter(item => item.status === 0)
      return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    countryList: function () {
      const list = this.$store.state.commonObj.countryList.filter(item => item.status === 0)
      return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    machineTypeList: function () {
      const list = this.$store.state.incentiveGrant.commonObj.macMachineTypeList.filter(item => item.status === 1)
      return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
      })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    circularList: function () {
      const dataList = this.$store.state.incentiveGrant.commonObj.subsidyCircularList.filter(item => item.status === 1)
        return dataList.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
    }
  },
  mounted () {
    core.index()
    flatpickr('#datepicker', {})
  },
  watch: {
    'formData.division_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.districtList = this.getDistrictList(newVal)
      }
    }
  },
  methods: {
    getSupplierMachineData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getBrandList (data) {
      const brandNameList = this.brandList.filter(item => item.instrument_name_id === parseInt(data.machine_id))
      data.brandNameList = brandNameList
      return data
    },
    getMachineNameList (data) {
      const machineList = this.$store.state.incentiveGrant.commonObj.instrumentList.filter(item => item.machine_type_id === parseInt(data.machine_type_id))
      data.machineList = machineList
      return data
    },
    async saveData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${SupplierMachineUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(incentiveGrantServiceBaseUrl, SupplierMachineStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)
      this.loading = false

      if (result.success) {
        this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$toast.error({
          title: this.$t('globalTrans.error'),
          message: result.message,
          color: '#D6E09B'
        })
        this.$refs.form.setErrors(result.errors)
      }
    },
    add () {
      const tampbpSetup = {
        origin_type: '',
        machine_type_id: '',
        machine_id: '',
        brand_id: '',
        country_origin_id: '',
        country_prepared_id: '',
        horse_power: '',
        model: '',
        extended_model: '',
        plain_area: '',
        brandNameList: [],
        machineList: [],
        coastal_area: ''
      }
      if (tampbpSetup) {
        this.formData.details.push(tampbpSetup)
      }
      // const key1 = parseInt(this.formData.details.length)
      // const item = this.formData.details[key1]
      // let isEmpty = true
      // Object.keys(item).map(key => {
      //   if (item[key] === '' || item[key] === 0) {
      //     isEmpty = false
      //   }
      // })
      // if (isEmpty === true) {
      //   this.formData.details.push(tampbpSetup)
      // }
    },
    remove (key) {
      this.formData.details.splice(key, 1)
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
        if (divisionId) {
          return districtList.filter(district => district.division_id === divisionId)
        }
      return districtList
    },
    async getSupplierName () {
      this.loader = true
      this.loading = true
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, '/farmMachinery/config/supplier-wise-machine-assign/supplier-list')
      if (result.success) {
        this.supplierList = result.data.map(item => {
          const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
        })
      } else {
        this.supplierList = []
      }
      this.loader = false
      this.loading = false
    }
  }
}
</script>
